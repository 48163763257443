import React, { Component } from "react";
import { Page, Card } from "@shopify/polaris";

import Input from "../../components/Form/Input/Input";
import Button from "../../components/Button/Button";
import { required, length, email } from "../../util/validators";
import Auth from "./Auth";

import "./Auth.css";

class Login extends Component {
  state = {
    loginForm: {
      email: {
        value: "",
        valid: false,
        touched: false,
        validators: [required, email]
      },
      password: {
        value: "",
        valid: false,
        touched: false,
        validators: [required, length({ min: 5 })]
      },
      formIsValid: false
    }
  };

  inputChangeHandler = (input, value) => {
    this.setState(prevState => {
      let isValid = true;
      const updatedForm = {
        ...prevState.loginForm,
        [input]: {
          ...prevState.loginForm[input],
          valid: isValid,
          value: value
        }
      };
      let formIsValid = true;
      return {
        loginForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        loginForm: {
          ...prevState.loginForm,
          [input]: {
            ...prevState.loginForm[input],
            touched: true
          }
        }
      };
    });
  };

  render() {
    return (
      <Page fullWidth>
        <div className="auth-container">
          <div className="card-container">
            <Card>
              <Card.Header title="Welcome" />
              <Card.Section subdued>
                <Auth>
                  <form
                    onSubmit={e =>
                      this.props.onLogin(e, {
                        email: this.state.loginForm.email.value,
                        password: this.state.loginForm.password.value
                      })
                    }
                  >
                    <Input
                      id="email"
                      label="E-Mail"
                      type="email"
                      control="input"
                      onChange={this.inputChangeHandler}
                      onBlur={this.inputBlurHandler.bind(this, "email")}
                      value={this.state.loginForm["email"].value}
                      valid={this.state.loginForm["email"].valid}
                      touched={this.state.loginForm["email"].touched}
                    />
                    <Input
                      id="password"
                      label="Password"
                      type="password"
                      control="input"
                      onChange={this.inputChangeHandler}
                      onBlur={this.inputBlurHandler.bind(this, "password")}
                      value={this.state.loginForm["password"].value}
                      valid={this.state.loginForm["password"].valid}
                      touched={this.state.loginForm["password"].touched}
                    />
                    <Button type="submit" loading={this.props.loading}>
                      Login
                    </Button>
                  </form>
                </Auth>
              </Card.Section>
            </Card>
          </div>
        </div>
      </Page>
    );
  }
}

export default Login;
