import React, { Fragment } from "react";

import "./MainLayout.css";

import MainNavigation from "../../components/Navigation/MainNavigation/MainNavigation";

const layout = props => (
  <Fragment>
    <header className="App-topbar">{props.header}</header>
    <aside className="App-sidebar">
      <div className="App-sidebar-container">
        <MainNavigation />
      </div>
    </aside>
  </Fragment>
);

export default layout;
