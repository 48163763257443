import React, { Fragment, Component } from "react";
import moment from "moment";
import { Page, Card, Badge, Button, ButtonGroup } from "@shopify/polaris";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { CSVLink } from "react-csv";
import _ from "lodash";

import DateRangePicker from "../../components/UI/DatePicker/DatePicker";

import MainLayout from "../../components/Layout/MainLayout";
import Toolbar from "../../components/Toolbar/Toolbar";

import "./Sponsors.css";

const today = new Date(new Date().setHours(24, 0, 0, 0));
const endDate = today.toISOString();
const m_today = moment();
const startDate = m_today.add("month", -1).toISOString();

class Sponsor extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      totalItems: "",
      startDate: startDate,
      endDate: endDate
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (startDate, endDate) => {
    if (startDate) {
      this.setState({
        startDate: moment(startDate._d)
          .utc()
          .endOf("day")
          .toISOString()
      });
    }
    if (endDate) {
      this.setState({
        endDate: moment(endDate._d)
          .utc()
          .endOf("day")
          .toISOString()
      });
    }
    setTimeout(
      function() {
        //Start the timer
        this.fetchSponsorDatas();
      }.bind(this),
      500
    );
  };

  componentDidMount() {
    this.fetchSponsorDatas();
  }

  fetchSponsorDatas() {
    fetch(
      process.env.REACT_APP_TUNNEL_URL +
        "/api/sponsors?startDate=" +
        this.state.startDate +
        "&endDate=" +
        this.state.endDate,
      {
        headers: {
          Authorization: "Bearer " + this.props.token
        }
      }
    )
      .then(response => response.json())
      .then(resData => {
        console.log(resData);
        this.setState({
          items: resData.sponsors,
          totalItems: resData.totalItems
        });
      });
  }

  render() {
    const { items, totalItems } = this.state;

    return (
      <Fragment>
        <MainLayout className="App" header={<Toolbar />} />
        <main className="App-main">
          <Page
            fullWidth
            title={"Sponsors"}
            titleMetadata={
              <Badge status="success">{totalItems} sponsors</Badge>
            }
          >
            <div className="header-menu">
              <ButtonGroup>
                <CSVLink
                  data={items.map(x => ({
                    Email: x.email,
                    Name: x.name,
                    Code_Filleul: x.code_fill,
                    Nb_of_Use: x.fill_count,
                    Code_Parrain: x.code_parr,
                    Amount: x.parr_amount,
                    Created_at: x.createdAt.split("T")[0]
                  }))}
                  filename={"asphalte-app-sponsors-exports.csv"}
                  className="download-button"
                  target="_blank"
                >
                  <Button>Download CSV</Button>
                </CSVLink>
                <Button primary onClick={() => this.fetchSponsorDatas()}>
                  Refresh Datas
                </Button>
              </ButtonGroup>
              <div className="header-dates">
                <p>
                  Choisir une période (<i>dernier mois par défaut</i>)
                </p>
                <DateRangePicker onRangeChange={this.onChange} />
              </div>
            </div>
            <Card>
              <ReactTable
                data={items}
                pageSize={totalItems}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                showPagination={false}
                columns={[
                  {
                    Header: "",
                    id: "row",
                    maxWidth: 40,
                    filterable: false,
                    Cell: row => {
                      return (
                        <div
                          style={{
                            textAlign: "right"
                          }}
                        >
                          {row.viewIndex + 1}
                        </div>
                      );
                    }
                  },
                  {
                    Header: "Email",
                    id: "email",
                    maxWidth: 280,
                    accessor: d => d.email,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["email"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Name",
                    id: "name",
                    maxWidth: 250,
                    accessor: d => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["name"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Code Filleul",
                    id: "code_fill",
                    maxWidth: 150,
                    accessor: d => d.code_fill,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["code_fill"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Nb of use",
                    id: "fill_count",
                    maxWidth: 100,
                    Cell: row => (
                      <div
                        style={{
                          textAlign: "right",
                          height: "100%"
                        }}
                      >
                        {row.value}
                      </div>
                    ),
                    accessor: d => d.fill_count,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["fill_count"]
                      }),
                    filterAll: true,
                    Footer: (
                      <span>
                        <div>
                          A: {_.round(_.mean(_.map(items, d => d.fill_count)))}
                        </div>
                        <div
                          style={{
                            color: "#3f4eae"
                          }}
                        >
                          S:{" "}
                          <strong>
                            {_.round(_.sum(_.map(items, d => d.fill_count)))}
                          </strong>
                        </div>
                      </span>
                    )
                  },
                  {
                    Header: "Code Parrain",
                    id: "code_parr",
                    maxWidth: 150,
                    accessor: d => d.code_parr,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["code_parr"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Amount",
                    id: "parr_amount",
                    maxWidth: 150,
                    Cell: row => (
                      <div
                        style={{
                          textAlign: "right",
                          height: "100%"
                        }}
                      >
                        {row.value}€
                      </div>
                    ),
                    accessor: d => d.parr_amount,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["parr_amount"]
                      }),
                    filterAll: true,
                    Footer: (
                      <span>
                        <div>
                          A: {_.round(_.mean(_.map(items, d => d.parr_amount)))}
                          {"€"}
                        </div>
                        <div
                          style={{
                            color: "#3f4eae"
                          }}
                        >
                          S:{" "}
                          <strong>
                            {_.round(_.sum(_.map(items, d => d.parr_amount)))}
                            {"€"}
                          </strong>
                        </div>
                      </span>
                    )
                  },
                  {
                    Header: "Created at",
                    id: "createdAt",
                    maxWidth: 100,
                    style: {
                      textAlign: "center"
                    },
                    accessor: d => d.createdAt.split("T")[0],
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["createdAt"]
                      }),
                    filterAll: true
                  }
                ]}
                defaultSorted={[
                  {
                    id: "price",
                    desc: true
                  }
                ]}
                style={{
                  height: "70vh"
                }}
                className="-striped -highlight"
              />
            </Card>
          </Page>
        </main>
      </Fragment>
    );
  }
}
export default Sponsor;
