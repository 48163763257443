import React, { Fragment, Component } from "react";
import { Page, Card, Button } from "@shopify/polaris";

import MainLayout from "../../components/Layout/MainLayout";
import Toolbar from "../../components/Toolbar/Toolbar";

import "./Configs.css";

class Configs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price_rule: [],
      shipping_price_range: [],
      subtotal_range: [],
      quantity_ratio: []
    };
  }

  componentDidMount() {}

  createPriceRule = event => {
    event.preventDefault();
    fetch(process.env.REACT_APP_TUNNEL_URL + "/configs/create-price-rule", {
      //method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.token
      }
    })
      .then(response => response.json())
      .then(resData => {
        console.log(resData);
        this.setState({
          price_rule: resData.discount_rule,
          shipping_price_range:
            resData.discount_rule.prerequisite_shipping_price_range,
          subtotal_range: resData.discount_rule.prerequisite_subtotal_range,
          quantity_ratio:
            resData.discount_rule.prerequisite_to_entitlement_quantity_ratio
        });
      })
      .catch(this.catchError);
  };

  render() {
    const {
      price_rule,
      shipping_price_range,
      subtotal_range,
      quantity_ratio
    } = this.state;

    return (
      <Fragment>
        <MainLayout className="App" header={<Toolbar />} />
        <main className="App-main">
          <Page fullWidth title={"Price Rule Configuration"}>
            <div className="mb">
              <Button primary onClick={this.createPriceRule}>
                Get AMI price rule
              </Button>
            </div>

            <Card title="Summary" subdued sectioned>
              <h3 className="h3">{price_rule.title}</h3>
              <ul className="summary">
                {shipping_price_range && (
                  <li>
                    Applies to shipping rates less than or equal to{" "}
                    <b>{shipping_price_range.less_than_or_equal_to}€</b>
                  </li>
                )}
                {subtotal_range && (
                  <li>
                    Minimum purchase of{" "}
                    <b>{subtotal_range.greater_than_or_equal_to}€</b>
                  </li>
                )}
              </ul>
            </Card>
            <Card title="Price Rule Parameters" sectioned>
              <ul>
                <li>
                  admin_graphql_api_id: <b>{price_rule.admin_graphql_api_id}</b>
                </li>
                <li>
                  allocation_limit: <b>{price_rule.allocation_limit}</b>
                </li>
                <li>
                  allocation_method: <b>{price_rule.allocation_method}</b>
                </li>
                <li>
                  created_at: <b>{price_rule.created_at}</b>
                </li>
                <li>
                  customer_selection: <b>{price_rule.customer_selection}</b>
                </li>
                <li>
                  ends_at: <b>{price_rule.ends_at}</b>
                </li>
                <li>
                  entitled_collection_ids:{" "}
                  <b>{price_rule.entitled_collection_ids}</b>
                </li>
                <li>
                  entitled_country_ids: <b>{price_rule.entitled_country_ids}</b>
                </li>
                <li>
                  entitled_product_ids:
                  {price_rule.entitled_product_ids &&
                    price_rule.entitled_product_ids.map(el => (
                      <b key={el}> [{el}] </b>
                    ))}
                </li>
                <li>
                  entitled_variant_ids: <b>{price_rule.entitled_variant_ids}</b>
                </li>
                <li>
                  id: <b>{price_rule.id}</b>
                </li>
                <li>
                  once_per_customer:
                  {price_rule.once_per_customer ? <b> true</b> : <b> false</b>}
                </li>
                <li>
                  prerequisite_collection_ids:{" "}
                  <b>{price_rule.prerequisite_collection_ids}</b>
                </li>
                <li>
                  prerequisite_customer_ids:{" "}
                  <b>{price_rule.prerequisite_customer_ids}</b>
                </li>
                <li>
                  prerequisite_product_ids:{" "}
                  <b>{price_rule.prerequisite_product_ids}</b>
                </li>
                <li>
                  prerequisite_quantity_range:{" "}
                  <b>{price_rule.prerequisite_quantity_range}</b>
                </li>
                <li>
                  prerequisite_saved_search_ids:{" "}
                  <b>{price_rule.prerequisite_saved_search_ids}</b>
                </li>
                <li>
                  prerequisite_shipping_price_range (less_than_or_equal_to):{" "}
                  {shipping_price_range && (
                    <b>{shipping_price_range.less_than_or_equal_to}€</b>
                  )}
                </li>
                <li>
                  prerequisite_subtotal_range (greater_than_or_equal_to):{" "}
                  {subtotal_range && (
                    <b>{subtotal_range.greater_than_or_equal_to}€</b>
                  )}
                </li>
                <li>
                  prerequisite_to_entitlement_quantity_ratio:{" "}
                  <b>
                    {quantity_ratio.entitled_quantity}{" "}
                    {quantity_ratio.prerequisite_quantity}
                  </b>
                </li>
                <li>
                  prerequisite_variant_ids:{" "}
                  <b>{price_rule.prerequisite_variant_ids}</b>
                </li>
                <li>
                  starts_at: <b>{price_rule.starts_at}</b>
                </li>
                <li>
                  target_selection: <b>{price_rule.target_selection}</b>
                </li>
                <li>
                  target_type: <b>{price_rule.target_type}</b>
                </li>
                <li>
                  title: <b>{price_rule.title}</b>
                </li>
                <li>
                  updated_at: <b>{price_rule.updated_at}</b>
                </li>
                <li>
                  usage_limit: <b>{price_rule.usage_limit}</b>
                </li>
                <li>
                  value: <b>{price_rule.value}</b>
                </li>
                <li>
                  value_type: <b>{price_rule.value_type}</b>
                </li>
              </ul>
              <p></p>
            </Card>
          </Page>
        </main>
      </Fragment>
    );
  }
}
export default Configs;
