import React from "react";

import appLogo from "../../assets/images/sponsorship-app-logo.jpg";
import "./Logo.css";

const logo = props => (
  <div className="logo">
    <img src={appLogo} alt="SponsorShip Logo" />
  </div>
);

export default logo;
