import React from "react";
import { NavLink } from "react-router-dom";

import "./NavigationItems.css";

import { Icon } from "@shopify/polaris";
import {
  HomeMajorMonotone,
  CashEuroMajorMonotone,
  GiftCardMajorMonotone,
  SettingsMajorMonotone
} from "@shopify/polaris-icons";

const navItems = [
  {
    id: "sponsors",
    url: "/",
    label: "Sponsors",
    icon: GiftCardMajorMonotone,
    auth: false
  },
  {
    id: "discounts",
    url: "/discounts",
    label: "Discounts",
    icon: CashEuroMajorMonotone,
    auth: false
  },
  {
    id: "configs",
    url: "/configs",
    label: "Configs",
    icon: SettingsMajorMonotone,
    auth: false
  }
];

const navigationItems = props => [
  ...navItems.map(item => (
    <li
      key={item.id}
      className={["navigation-item", props.mobile ? "mobile" : ""].join(" ")}
    >
      <NavLink to={item.url} exact onClick={props.onChoose}>
        <div className="Polaris-Navigation__Icon">
          <Icon source={item.icon} />
        </div>
        <div>{item.label}</div>
      </NavLink>
    </li>
  )),
  props.isAuth && (
    <li className="navigation-item" key="logout">
      <button onClick={props.onLogout}>Logout</button>
    </li>
  )
];

export default navigationItems;
