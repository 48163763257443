import React from "react";
import {} from "react-router-dom";

import NavigationItems from "../NavigationItems/NavigationItems";

import { Navigation } from "@shopify/polaris";

import "./MainNavigation.css";

const mainNavigation = props => (
  <Navigation location="/">
    <ul className="Polaris-Navigation__Section">
      <NavigationItems />
    </ul>
  </Navigation>
);

export default mainNavigation;
