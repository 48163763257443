import React, { Component } from "react";
import "react-dates/initialize";
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  DateRangePickerShape
} from "react-dates";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import { START_DATE, END_DATE } from "react-dates/constants";

import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import omit from "lodash/omit";

import "./DatePicker.css";

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  stateDateWrapper: PropTypes.func,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  ...omit(DateRangePickerShape, [
    "startDate",
    "endDate",
    "onDatesChange",
    "focusedInput",
    "onFocusChange"
  ])
};

const defaultProps = {
  showDefaultInputIcon: true,
  numberOfMonths: 1,
  startDateId: START_DATE,
  endDateId: END_DATE,
  noBorder: true,
  // internationalization
  endDatePlaceholderText: "Date de fin",
  startDatePlaceholderText: "Date de début",
  displayFormat: () => moment.localeData("fr").longDateFormat("L"),
  stateDateWrapper: date => date
};

let campaignStartDate = "2019-09-09T00:00:00-00:00";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    moment.locale("fr", {
      months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
      monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split(
        "_"
      ),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
        ISO: "YYYY-MM-DD HH:mm:ss"
      },
      calendar: {
        sameDay: "[Aujourd’hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L"
      },
      relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        M: "un mois",
        MM: "%d mois",
        y: "un an",
        yy: "%d ans"
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function(number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /PD|MD/,
      isPM: function(input) {
        return input.charAt(0) === "M";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function(hours, minutes, isLower) {
        return hours < 12 ? "PD" : "MD";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // Used to determine first week of the year.
      }
    });
    let focusedInput = null;

    if (props.autoFocus) {
      focusedInput = "startDate";
    } else if (props.autoFocusEndDate) {
      focusedInput = "endDate";
    }
    this.state = {
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      focusedInput
    };
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  /*onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };*/

  onDatesChange({ startDate, endDate }) {
    const { stateDateWrapper } = this.props;
    //console.log("startDate: ", startDate);
    //console.log("endDate: ", endDate);
    this.props.onRangeChange(startDate, endDate);
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  //isOutsideRange = day => !isInclusivelyBeforeDay(day, moment());

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const props = omit(this.props, [
      "onRangeChange",
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "stateDateWrapper"
    ]);
    return (
      <DateRangePicker
        {...props}
        startDate={startDate} // momentPropTypes.momentObj or null,
        endDate={endDate} // momentPropTypes.momentObj or null,
        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
        //isOutsideRange={this.isOutsideRange}
        isOutsideRange={d =>
          d.isBefore(campaignStartDate) || !isInclusivelyBeforeDay(d, moment())
        }
      />
    );
  }
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
