import React from "react";
import { Button } from "@shopify/polaris";

import "./Toolbar.css";
import Logo from "../../components/Logo/Logo";

const toolbar = props => (
  <div className="App-topbar-container">
    <div className="App-topbar-brand">
      <a href="/">
        <Logo />
        <h1>Sponsor App</h1>
      </a>
    </div>
    <div className="App-topbar-fill" />
    <div className="App-topbar-profile">
      <Button outline>Logout</Button>
    </div>
  </div>
);

export default toolbar;
