import React, { Fragment, Component } from "react";
import moment from "moment";
import {
  Page,
  Card,
  Badge,
  Button,
  ButtonGroup,
  Spinner
} from "@shopify/polaris";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { CSVLink } from "react-csv";
import _ from "lodash";

import DateRangePicker from "../../components/UI/DatePicker/DatePicker";

import MainLayout from "../../components/Layout/MainLayout";
import Toolbar from "../../components/Toolbar/Toolbar";

import "./Discounts.css";

const today = new Date(new Date().setHours(24, 0, 0, 0));
const endDate = today.toISOString();
const m_today = moment();
const startDate = m_today.add("month", -1).toISOString();

class Discount extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      totalOrders: "",
      totalDiscounts: "",
      newOrdersCount: 0,
      startDate: startDate,
      endDate: endDate
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (startDate, endDate) => {
    if (startDate) {
      this.setState({
        startDate: moment(startDate._d)
          .utc()
          .endOf("day")
          //.format("YYYY-MM-DDTHH:mm:ss")
          //.toDate()
          .toISOString()
      });
    }
    if (endDate) {
      this.setState({
        endDate: moment(endDate._d)
          .utc()
          .endOf("day")
          //.format("YYYY-MM-DDTHH:mm:ss")
          //.toDate()
          .toISOString()
      });
    }
    //this.getOrdersFromDatabase();
    setTimeout(
      function() {
        //Start the timer
        this.getOrdersFromDatabase();
      }.bind(this),
      500
    );
  };

  componentDidMount() {
    this.getOrdersFromDatabase();
  }

  // Get all orders from Database
  getOrdersFromDatabase() {
    fetch(
      process.env.REACT_APP_TUNNEL_URL +
        "/api/discounts?startDate=" +
        this.state.startDate +
        "&endDate=" +
        this.state.endDate,
      {
        headers: {
          Authorization: "Bearer " + this.props.token
        }
      }
    )
      .then(response => response.json())
      .then(resData => {
        console.log(resData);
        this.setState({
          items: resData.discounts,
          totalItems: resData.discounts.length,
          totalOrders: resData.all_count,
          newOrdersCount: ""
        });
      })
      .catch(this.catchError);
  }

  // Save all orders in database
  refreshOrdersDatas() {
    this.setState({ loadingAll: true }, () => {
      fetch(process.env.REACT_APP_TUNNEL_URL + "/api/discounts/save", {
        headers: {
          Authorization: "Bearer " + this.props.token
        }
      })
        .then(response => response.json())
        .then(resData => {
          console.log(resData);
          this.setState({
            //totalOrders: resData.all_count,
            loadingAll: false
          });
        })
        .catch(this.catchError);
    });
  }

  render() {
    const {
      items,
      loadingNew,
      loadingAll,
      totalOrders,
      totalItems,
      newOrdersCount
    } = this.state;

    return (
      <Fragment>
        <MainLayout className="App" header={<Toolbar />} />
        <main className="App-main">
          <Page
            fullWidth
            title={"Discounts Orders"}
            titleMetadata={
              <Badge status="success">
                {totalItems} / {totalOrders} orders
              </Badge>
            }
          >
            <div className="header-menu">
              <ButtonGroup>
                <CSVLink
                  data={items.map(x => ({
                    Promo_code: x.discount_codes ? x.discount_codes.code : "",
                    Email: x.email,
                    Name: x.shipping_address ? x.shipping_address.name : "",
                    State: x.customer ? x.customer.state : "",
                    Phone: x.shipping_address ? x.shipping_address.phone : "",
                    Address: x.shipping_address
                      ? x.shipping_address.address1
                      : "",
                    City: x.shipping_address ? x.shipping_address.zip : "",
                    Order: x.order_number,
                    Amount: x.price,
                    Created_at: x.order_date.split("T")[0]
                  }))}
                  filename={"asphalte-app-discount-exports.csv"}
                  className="download-button"
                  target="_blank"
                >
                  <Button>Download CSV</Button>
                </CSVLink>
                <Button primary onClick={() => this.getOrdersFromDatabase()}>
                  Refresh Screen
                </Button>
                <Button destructive onClick={() => this.refreshOrdersDatas()}>
                  {loadingAll ? (
                    <Spinner
                      accessibilityLabel="Loading"
                      size="small"
                      color="white"
                    />
                  ) : (
                    "Refresh Database"
                  )}
                </Button>
              </ButtonGroup>
              <div className="header-dates">
                <p>
                  Choisir une période (<i>dernier mois par défaut</i>)
                </p>
                <DateRangePicker onRangeChange={this.onChange} />
              </div>
            </div>
            <Card>
              <ReactTable
                data={items}
                pageSize={totalItems}
                filterable
                showPagination={false}
                defaultSorted={[
                  {
                    id: "order_number",
                    desc: true
                  }
                ]}
                style={{
                  height: "70vh"
                }}
                className="-striped -highlight"
                columns={[
                  {
                    Header: "",
                    id: "row",
                    maxWidth: 40,
                    filterable: false,
                    Cell: row => {
                      return (
                        <div
                          style={{
                            textAlign: "right"
                          }}
                        >
                          {row.viewIndex + 1}
                        </div>
                      );
                    }
                  },
                  {
                    Header: "Promo Code",
                    id: "code",
                    width: 130,
                    accessor: d => d.discount_codes.code,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["code"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Name",
                    id: "name",
                    width: 200,
                    accessor: d => d.shipping_address ? d.shipping_address.name : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["name"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Email",
                    id: "email",
                    minWidth: 220,
                    accessor: d => d.email,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["email"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Phone",
                    id: "phone",
                    minWidth: 120,
                    accessor: d => d.shipping_address ? d.shipping_address.phone : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["phone"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Address",
                    id: "address",
                    minWidth: 200,
                    style: {
                      whiteSpace: "unset"
                    },
                    accessor: d => d.shipping_address ? d.shipping_address.address1 : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["address"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "City",
                    id: "zip",
                    width: 80,
                    style: {
                      whiteSpace: "unset"
                    },
                    accessor: d => d.shipping_address ? d.shipping_address.zip : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["zip"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "#Order",
                    id: "order_number",
                    width: 60,
                    style: {
                      textAlign: "right"
                    },
                    accessor: d => d.order_number,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["order_number"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Amount",
                    id: "price",
                    width: 80,
                    Cell: row => (
                      <div
                        style={{
                          textAlign: "right",
                          height: "100%"
                        }}
                      >
                        {row.value}€
                      </div>
                    ),
                    accessor: d => d.price,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["price"]
                      }),
                    filterAll: true,
                    Footer: (
                      <span>
                        <div>
                          A: {_.round(_.mean(_.map(items, d => d.price)))}
                          {"€"}
                        </div>
                        <div
                          style={{
                            color: "#3f4eae"
                          }}
                        >
                          S:{" "}
                          <strong>
                            {_.round(_.sum(_.map(items, d => d.price)))}
                            {"€"}
                          </strong>
                        </div>
                      </span>
                    )
                  },
                  {
                    Header: "Payment Status",
                    id: "status",
                    width: 120,
                    style: {
                      textAlign: "center"
                    },
                    accessor: d => d.payment_status,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["status"]
                      }),
                    filterAll: true
                  },
                  {
                    Header: "Order Date",
                    id: "date",
                    maxWidth: 180,
                    style: {
                      textAlign: "center"
                    },
                    accessor: d => d.order_date.split("T")[0],
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["date"]
                      }),
                    filterAll: true
                  }
                ]}
              />
            </Card>
          </Page>
        </main>
      </Fragment>
    );
  }
}
export default Discount;
