import React, { Fragment, Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import "./App.css";

import LoginPage from "./pages/Auth/Login";
import SponsorsPage from "./pages/Sponsors/Sponsors";
import DiscountsPage from "./pages/Discounts/Discounts";
import ConfigsPage from "./pages/Configs/Configs";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";

// Work with Polaris
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/styles.css";

class App extends Component {
  state = {
    showBackdrop: false,
    isAuth: false,
    token: null,
    userId: null,
    authLoading: false,
    error: null
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    const expiryDate = localStorage.getItem("expiryDate");
    if (!token || !expiryDate) {
      return;
    }
    if (new Date(expiryDate) <= new Date()) {
      this.logoutHandler();
      return;
    }
    const userId = localStorage.getItem("userId");
    const remainingMilliseconds =
      new Date(expiryDate).getTime() - new Date().getTime();
    this.setState({ isAuth: true, token: token, userId: userId });
    this.setAutoLogout(remainingMilliseconds);
  }

  backdropClickHandler = () => {
    this.setState({ showBackdrop: false, error: null });
  };

  logoutHandler = () => {
    this.setState({ isAuth: false, token: null });
    localStorage.removeItem("token");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("userId");
  };

  loginHandler = (event, authData) => {
    event.preventDefault();
    this.setState({ authLoading: true });
    fetch(process.env.REACT_APP_TUNNEL_URL + "/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: authData.email,
        password: authData.password
      })
    })
      .then(res => {
        if (res.status === 422) {
          //console.log(authData.email);
          throw new Error("Validation failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return res.json();
      })
      .then(resData => {
        //console.log(resData);
        this.setState({
          isAuth: true,
          token: resData.token,
          authLoading: false,
          userId: resData.userId
        });
        localStorage.setItem("token", resData.token);
        localStorage.setItem("userId", resData.userId);
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        localStorage.setItem("expiryDate", expiryDate.toISOString());
        this.setAutoLogout(remainingMilliseconds);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isAuth: false,
          authLoading: false,
          error: err
        });
      });
  };

  setAutoLogout = milliseconds => {
    setTimeout(() => {
      this.logoutHandler();
    }, milliseconds);
  };

  errorHandler = () => {
    this.setState({ error: null });
  };

  render() {
    let routes = (
      <Switch>
        <Route
          path="/"
          exact
          render={props => (
            <LoginPage
              {...props}
              onLogin={this.loginHandler}
              loading={this.state.authLoading}
            />
          )}
        />
        <Redirect to="/" />
      </Switch>
    );
    if (this.state.isAuth) {
      routes = (
        <Switch>
          <Route
            path="/"
            exact
            render={props => (
              <SponsorsPage
                userId={this.state.userId}
                token={this.state.token}
              />
            )}
          />
          <Route
            path="/discounts"
            exact
            render={props => (
              <DiscountsPage
                userId={this.state.userId}
                token={this.state.token}
              />
            )}
          />
          <Route
            path="/configs"
            exact
            render={props => (
              <ConfigsPage
                userId={this.state.userId}
                token={this.state.token}
              />
            )}
          />
          <Redirect to="/" />
        </Switch>
      );
    }
    return (
      <AppProvider>
        <Fragment>
          <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
          {routes}
        </Fragment>
      </AppProvider>
    );
  }
}

export default withRouter(App);
